/* zip-routing-details.css */
/* Container styles */
.routing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: white;
}

/* General page styles */
body {
  font-family: "Arial", "Helvetica", sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}

/* Routing Guide Heading */
h1 {
  margin-top: 20px !important;
  text-align: left;
  font-size: 24px;
  margin: 20px 0;
  color: #993366 !important;
  font-weight: bold !important;
  font-family: "Arial", sans-serif;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
}

.nav-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #ad3477;
  color: rgb(249, 249, 245);
  border-radius: 4px;
  max-height: 37px;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.nav-button:hover {
  background-color: #bf5c85;
  transform: translateY(-2px);
}

.nav-button span:first-child {
  font-size: 20px;
  font-weight: bold;
  padding-right: 16px;
  padding-bottom: 2px;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 10px 0; /* Reduced bottom margin to minimize gaps */
  background-color: rgb(233, 230, 230);
  box-shadow: 0px 4px 8px rgba(23, 23, 23, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

th,
td {
  padding: 15px;
  border: 2px solid #040404;
  text-align: left;
}

th {
  background-color: #993366;
  color: rgb(235, 231, 232);
  font-weight: 400;
  font-family: sans-serif;
  text-transform: uppercase;
}

td {
  color: #3d3838;
  transition: background-color 0.2s ease;
}

tr:hover {
  background-color: white;
}

/* Link styles */
a {
  color: #993366;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
  color: #bbc3ce;
}

/* Notes section */
p {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #060000;
  line-height: 1.6;
}

p b {
  color: #222222;
  font-weight: 600;
}

h6 {
  color: #3d3838;
  margin: 15px 0;
  padding: 0;
}

/* ZipRoutingDetails.css */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
}

.nav-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #993366;
  color: rgb(
    249,
    249,
    245
  ) !important; /* Added !important to ensure color is applied */
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.nav-button:hover {
  background-color: #8a255c;
  transform: translateY(-2px);
  color: rgb(249, 249, 245) !important;
  text-decoration: none;
}

.nav-button .arrow {
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
}

.nav-button .button-text {
  font-weight: normal;
}
.dialog-container {
  /* Center the dialog */
  display: flex;
  justify-content: center;

  align-items: center;
}

.dialog-title {
  background-color: #f5f5f5;
  color: #3e6b90;
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}
