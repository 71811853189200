.document-library {
  margin: 20px;
}

.document-library h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.document-library ul {
  list-style-type: none;
}

.document-library li {
  margin-bottom: 10px;
}

.document-library a {
  color: #007bff;
  text-decoration: none;
}

.document-library a:hover {
  text-decoration: underline;
}


.document-library-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.library-item {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.library-item h2 {
  margin-bottom: 10px;
  font-size: 1.25rem;
}

/* Single column document list (default for smaller titles) */
.documents-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Multi-column layout for large number of documents */
.multi-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 columns for large document lists */
  gap: 15px;
}

.document-link {
  text-decoration: none;
  color: #0073e6;
  font-weight: bold;
}

.document-link:hover {
  text-decoration: underline;
}

.library-item.large {
  grid-column: span 3; /* Make large sections span the full width */
}

.loader-container {
  position: fixed; /* Fixes it to the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: add a background overlay */
  z-index: 9999; /* Make sure it's on top of other content */
}

.loader {
  position: relative;
  text-align: center;
}

.loader img {
  display: block;
  max-width: 100px; /* Adjust size if necessary */
  margin: 0 auto;
}

.loader p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust text color for visibility */
  font-size: 16px; /* Adjust font size if necessary */
}
.grouped-content{
background-color: #fff;
min-height: 100vh;
padding: auto;
width: 100%;
}

