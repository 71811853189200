.contacts-container {
  padding: 50px;
  font-family: 'Arial', sans-serif;
}

h2, h3 {
  text-align: left;
  color: #b10e69; /* Custom color for headings */
}

h4 {
  font-size: 1.2em;
  margin: 20px 0 15px; /* Adjust margin for consistent spacing */
}

.contacts-section {
  margin-top: 25px;
}

.contacts-section table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px; /* Consistent spacing below each table */
  table-layout: fixed; /* Fixed table layout */
}

.contacts-section th, .contacts-section td {
  border: 1px solid #141213;
  padding: 15px; /* Increase padding for better readability */
  text-align: left;
  word-wrap: break-word; /* Ensure content wraps within cells */
}

.contacts-container p {
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #141213;
  line-height: 1.6;
}

.contacts-container th,
td {
  padding: 5px;
  border: 2px solid #040404;
  text-align: left;
}

.contacts-section th {
  background-color: pink; /* Make header row pink */
  font-weight: bold;
}

.contacts-section td {
  font-size: 0.9em;
  color: #333;
}

.contacts-container h3, .contacts-container h4 {
  color: #000000;
  font-weight: 700; /* Custom color for headings */
}

.contacts-container h2 {
  color: #b10e69;
  font-weight: 700; /* Custom color for headings */
}

.contacts-container h4 {
  font-size: 1.2em;
  margin: 15px 0 10px; /* Adjust margin for consistent spacing */
}
