/* ContentPage.css */
.no-decoration {
  color: #617F90;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.content-page {
  background-color: #fff;
  min-height: 100vh;
  padding: 20px 20px 20px 300px;
  width: 100%;
}

.content-page h1,
.alerts-content h2,
.description .Main-Title h1 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #8f517b;
}

.content-description {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.content-description p{
  margin: 0;
  color: #ffffff;
  line-height: 1.6;
}

.nav-tabs .nav-link {
  color: #333;
  font-weight: bold;
  border-radius: 0;
  border: 1px solid #e0e0e0;
  margin-right: 5px;
  font-size: 15px;
}

.nav-tabs .nav-link.active {
  background-color: #f8f8f8;
  border-bottom: 2px solid #333;
}

.tab-content {
  padding: 15px;
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 4px;
}

.content-page p, .content-page li {
  line-height: 1.6;
  color: #666;
}

.content-page a {
  color: #0066cc;
  text-decoration: none;
}

.content-page a:hover {
  text-decoration: underline; /* Underline on hover */
}

.main-page {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 20px 20px 20px 320px;
  width: 100%;
}
.main-page > h1{
  font-size: 30px;
  font-weight: 600;
  color: #8f517b;
  margin-bottom: 30px;
}

/*=================================== My style =================================*/

.content-page {
  background-color: #fff;
  min-height: 100vh;
  padding: 20px 20px 20px 320px;
  width: 100%;
}
.content-page > h1{
  font-size: 30px;
  font-weight: 600;
  color: #444;
  margin-bottom: 30px;
}

.content-page h2,
.content-page h3,
.content-page h4,
.content-page h5,
.content-page h6{
  color: #444;
  font-weight: 600;
  margin-bottom: 10px;
}
.content-page h5{
  text-align: center;
  color: #444;
  font-weight: 600;
  margin-bottom: 10px;

}
.content-page p,
.alerts-content p,
.description p{
  font-size: 16px;
  color: #444;
  line-height: 28px;
  margin: 0 0 10px;
}
.content-page ul li,
.alerts-content ul li,
.description ul li{
  list-style-type: none;
  font-size: 16px;
  color: #444;
  line-height: 24px;
  background: url(../../assets/icon1.png) no-repeat left 9px;
  padding-left: 16px;
  margin: 0 0 8px;
}
.content-page ul li p,
.description ul li p{
  margin: 0;
}
.content-page ol li,
.description ol li{
  font-size: 16px;
  color: #444;
  line-height: 24px;
  margin: 0 0 8px;
}
.content-page ol li p,
.description ol li p{
  margin: 0;
}
.content-page ul.nav-tabs{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.content-page ul.nav-tabs li{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.content-page .tab-content{
  margin: 0;
  border: 0;
  border-radius: 0;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.content-page p a,
.description p a{
  color: #617f90;
}
.content-page p a:hover,
.description p a:hover{
  color: #c2185b;
}
.content-page img{
  margin: 0 0 15px;
  max-width: 100%;
  height: auto !important;
  display: block;
  border: 2px solid #fff;
  box-shadow: rgba(0,0,0,0.3)0 0 10px;
  border-radius: 6px;
}
.customBorder {
  border: 1px solid #e3e3e3;
  padding: 15px;
  border-radius: 10px;
}
button.pink_bg{
  background-color: transparent;
  color: #ad3477;
  border-color: #ad3477;
}
button.pink_bg:hover{
  background-color: #ad3477;
  color: #fff;
  border-color: #ad3477;
}

.description {
  display: flex;
  flex-wrap: wrap;
}
.description .customBorder{
  flex: 1 0 0%;
}
.description .notifications-section{
  flex: 1 0 0%;
  max-width: 300px;
  padding-left: 20px;
}

.right_side_image img{
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0,0,0,0.1) 0 0 10px;
  margin: 0 0 15px;
}
.notifications_heading{
  padding: 15PX 20PX;
  background: linear-gradient(to bottom, #765591 0%, #a45292 100%);
  /* font-size: 26px; */
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  border-radius: 10px 10px 0 0;
}
.notifications-box{
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  padding: 0 10px 10px 10px;
  border-radius: 0 0 10px 10px;
}
.notifications-box .notification-item{
  padding: 10px 0;

}
.notifications-box .notification-item .no-decoration{
  
    color: #617F90;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  
  
}
.notifications-box .notification-item + .notification-item{
  border-top: 1px solid #cccccc61;
}
.notifications-box h3{
  font-size: 15px;
  font-weight: 600;
  color: #617f90;
  margin: 0 0 5px;
  line-height: normal;
}
.notifications-box p{
  font-size: 14px;
  color: #444;
  line-height: 22px;
  margin: 0;
}

@media only screen and (max-width: 640px){
  .content-page {
    min-height: initial;
    width: 100%;
    display: block;
    margin: 0;
    padding: 15px 15px;
    border-top: 1px solid #ccc;
}
}


/* 
css changes for documemt updates box  */

.notifications-box-2 {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  padding: 0 10px 10px 10px;
  border-radius: 0 0 10px 10px;
}

.update-item {
  padding: 10px 0;
  border-bottom: 1px solid #cccccc61;
}

.update-item:last-child {
  border-bottom: none;
}

.no-decoration-2 {
  display: block;
  color: #617F90;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 8px;
}

.preview-text {
  font-size: 14px;
  color: #444;
  line-height: 1.5;
}

.notifications_heading {
  margin: 0;
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
}