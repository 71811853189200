.porouting-guide-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: white;
}
h3 {
  font-family: "Arial", sans-serif; /* You can replace "Arial" with any preferred font */
  font-size: 1.8rem; /* Adjust size as needed */
  font-weight: bold; /* Makes the text bold */
  text-align: center; /* Centers the text */
  color: #333; /* A subtle dark color */
  margin: 20px 0; /* Adds spacing above and below the heading */
  line-height: 1.4; /* Improves readability */
}

/* Wrapper for the formatted data */

/* Styling for the data box */
/* Wrapper for centering the formatted data */
.center-wrapper {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  /* min-height: 50vh;  Full viewport height */
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
  box-sizing: border-box; /* Ensure padding is included in size */
}

/* Styling for the data box */
/* Wrapper for the formatted data */
.formatted-data {
  font-family: "Arial", sans-serif; /* Clean and modern font */
  font-size: 1.1rem; /* Slightly larger font for better readability */
  line-height: 1.6; /* Improved line spacing */
  color: #050505; /* Dark text for high readability */
  background-color: #de28c9; /* Soft light blue background */
  border: 1px solid #99ccff; /* Subtle border */
  border-radius: 12px; /* Rounded corners for a smooth look */
  padding: 20px; /* Adequate spacing inside the box */
  margin: 20px auto; /* Centered with some space around it */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
  max-width: 700px; /* Limit the width */
  text-align: center; /* Align text to the left for a cleaner look */
  white-space: pre-wrap; /* Preserve new lines in the content */
}

/* Styling for headings (e.g., PO Number, Vendor Name) */
.formatted-data .heading {
  font-weight: 600; /* Semi-bold for better emphasis */
  color: #852090; /* Dark blue for a professional and distinct look */
  margin-bottom: 8px; /* Space between heading and content */
  display: inline-block; /* Ensures it stays on the same line with the content */
  padding-right: 5px; /* Add a little spacing between heading and content */
}

/* Styling for content (values like PO005, Vendor Name) */
.formatted-data .content {
  color: #333; /* Standard dark text color */
  display: inline-block; /* Ensures content aligns properly with headings */
}

/* Optional: For the entire formatted data, when hovered */
.formatted-data:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly larger shadow on hover */
  background-color: #abd3ee; /* Change background slightly when hovered */
}

/* Optional: For better responsive design */
@media (max-width: 768px) {
  .formatted-data {
    font-size: 1rem; /* Reduce font size on smaller screens */
    padding: 15px; /* Reduce padding for smaller devices */
    max-width: 90%; /* Use 90% width to be more responsive */
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}


.content {
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.formatted-data {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  background-color: #f9f9f9;
}

.instructions {
  margin-bottom: 20px;
  font-size: 14px;
}

.form {
  margin-bottom: 20px;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #218838;
}

.footer-text {
  font-size: 12px;
  color: #555;
  margin-top: 20px;
}
p {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

b {
  font-family: "Arial", sans-serif;
  font-weight: 400;
}
.form-field button {
  padding: 10px 20px;
  background-color: #5e2a84;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.form-field button:hover {
  background-color: #4a1d6c;
}

/* Header styling */
.porouting-guide-page h3 {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  color: #993366; /* Match the purple from the second image */
  margin-bottom: 30px;
  text-transform: uppercase;
}

/* Back button styling */
.porouting-guide-page .back-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #993366; /* Match the purple */
  padding: 10px;
  border: 2px solid #993366;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
}

.porouting-guide-page .back-button:hover {
  background-color: #993366;
  color: #fff;
}

.porouting-guide-page .back-button span {
  margin-left: 10px;
}

/* Centering and styling the formatted data section */
.porouting-guide-page .center-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.porouting-guide-page .formatted-data {
  background-color: #fafafa;
  padding: 20px;
  border: 2px solid #8a255c; 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  max-width: 600px;
  color: #333;
}

/* Form styling */
.porouting-guide-page .form {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}

.porouting-guide-page .form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.porouting-guide-page .form-field label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #993366;
}

.porouting-guide-page .form-field input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.porouting-guide-page .form-field input:focus {
  outline: none;
  border-color: #993366;
  box-shadow: 0 0 4px rgba(153, 51, 102, 0.5);
}

.porouting-guide-page .submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.porouting-guide-page .submit-button:hover {
  background-color: #0056b3;
}

/* Footer link styling */
.porouting-guide-page .footer-text a {
  color: #007bff;
  text-decoration: underline;
}

.porouting-guide-page .footer-text a:hover {
  text-decoration: none;
  color: #0056b3;
}

.porouting-guide-page .footer-text {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
  text-align: center;
}

.porouting-guide-page .form-field {
  display: flex;
  flex-direction: row; /* Keep label, input, and button in a vertical stack */
  align-items: center; /* Center the elements horizontally */
  max-width: 350px; /* Adjust as needed */
  margin: 0 auto; /* Centers the row */
  gap: 16px; /* Adds space between elements */
  justify-content: space-between; /* Distribute elements evenly */
}

.porouting-guide-page .form-field label {
  font-size: 14px;
  font-weight: bold;
  color: #993366; /* Purple text for the label */
  margin-bottom: 5px; /* Add some spacing below the label */
  min-width: 120px; /* Set a minimum width for consistent alignment */
}

.porouting-guide-page .form-field input {
  padding: 8px;
  width: 100px; /* Set a smaller, compact width for the input box */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  text-align: center; /* Center the text inside the input box */
  align-items: baseline; /* Align the input box with the label */
}

.porouting-guide-page .form-field input:focus {
  outline: none;
  border-color: #993366;
  box-shadow: 0 0 4px rgba(153, 51, 102, 0.5);
}

.porouting-guide-page .form-field button {
  background-color: #ad3477;
  color: white;
  padding: 8px 16px; /* Adjust padding for a compact look */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  max-height: 37px; /* Limit the height of the button */
}

.porouting-guide-page .form-field button:hover {
  background-color: #8a255c;
}

/* Back Button Styling */
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray background */
  border: 2px solid #ccc; /* Subtle border */
  padding: 8px 16px;
  border-radius: 10px; /* Rounded edges */
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Dark gray text */
  transition: all 0.3s ease-in-out; /* Smooth hover effect */
}

.back-button:hover {
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text on hover */
  border-color: #007bff; /* Border matches background on hover */
}

/* Icon Wrapper Styling */
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Circle */
  background-color: #007bff; /* Blue background */
  color: white; /* White arrow icon */
  margin-right: 10px; /* Space between icon and text */
  transition: background-color 0.3s ease-in-out; /* Smooth hover effect */
}

.back-button:hover .icon-wrapper {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Text Styling */
.button-text {
  font-size: 14px;
  color: #fff; /* Dark gray text */
}

.back-button:hover .button-text {
  color: #fff; /* White text on hover */
}
