/* NavigationBar.css */

/* General styles for the navigation bar */
.top-navbar {
  background-color: #f8f8f8;
  border-bottom: 2px solid #c2185b;
  padding: 0 20px;
}

.navbar-brand-custom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
}

.brand-logo {
  font-size: 1.5rem;
  color: #41b6e6;
  /* Teal color for "Neiman Marcus Group" */
}

.brand-title {
  font-size: 1.2rem;
  color: #282828;
  /* Grey color for "OPERATIONS PORTAL" */
}

/* Dropdown menu styles */
.navbar-nav .dropdown-menu {
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
}

.dropdown-header {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  background-color: #f8f8f8;
  padding: 8px 12px;
  margin-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.dropdown-column {
  padding: 0 10px;
  min-width: 200px;
  flex: 1;
}

.dropdown-menu .dropdown-item {
  padding: 8px 10px;
  color: #333;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f0f0f0;
}

.navbar-nav .dropdown-menu {
  display: block;
  transition: opacity 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.navbar-nav .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}

.navbar-nav .nav-link {
  font-weight: bold;
  color: #333;
  margin-right: 15px;
}

.navbar-nav .nav-link:hover {
  color: #c2185b;
}

/* Multi-Column Dropdown Styling */
.multi-column-dropdown .dropdown-menu-content {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #f8f8f8;
  max-width: 800px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  margin-top: -1px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-nav .dropdown-menu {
  padding: 0;
  margin: 0;
}

.multi-column-dropdown .dropdown-column {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.multi-column-dropdown .dropdown-header {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #333;
}

.dropdown-menu .dropdown-header {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  background-color: #f8f8f8;
  padding: 8px 20px;
}

.nav-tabs {
  border-bottom: 2px solid #e0e0e0;
}

.nav-tabs .nav-link {
  color: #000;
  margin-right: 10px;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  background-color: #f8f8f8;
}

.nav-tabs .nav-link.active {
  background-color: #ffffff;
  border-bottom: 2px solid #ffffff;
}

.custom-navbar {
  background-color: #f8f8f8;
  border-bottom: 2px solid #c2185b;
  padding: 0 20px;
}

.navbar-brand-custom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
}

.navbar-nav:hover > .dropdown-menu-content {
  display: flex;
}

.navbar-nav {
  position: relative;
  margin: 0;
  padding: 0;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.menu-container {
  border-top: 2px solid #c2185b;
  padding-top: 5px;
}

/* ContentfulSidebar styles */
/* .contentfulsidebar {
  width: 100%;
  max-width: 280px;
  position: absolute;
  top: 149px;
  left: 0;
  background-color: #dfdfdf;
  height: 100%;
  min-height: calc(100vh - 150px);
  padding: 0;
}
.contentfulsidebar > h3 {
  padding: 20px;
  background-color: #41b6e6;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
} */

.contentfulsidebar .accordion-item {
  border: none;
}

.contentfulsidebar .accordion-button {
  background-color: #ffffff;
  color: #0a0a0a;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: -0.9em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.contentfulsidebar .accordion-button:hover {
  background-color: #f0f0f0;
}

.contentfulsidebar .accordion-collapse {
  background-color: #e7e7e78c;
  padding: 10px 12px;
  border-radius: 6px;
}

.contentfulsidebar .accordion-body {
  padding: 0;
}

.contentfulsidebar ul {
  list-style: none;
  padding-left: 10px;
  margin-bottom: 5px;
}

.contentfulsidebar li {
  margin-bottom: 8px;
}

.contentfulsidebar li a {
  text-decoration: none;
  color: #0066cc;
  font-size: 0.9em;
}

.contentfulsidebar li a:hover {
  text-decoration: underline;
}

/* Styles to highlight active content */
.contentfulsidebar .accordion-item.active-submenu > .accordion-button {
  background-color: #e6f2ff;
  /* Light blue background for active submenu */
  color: #0b0d0e;
  /* Blue text for active submenu */
}

.contentfulsidebar .accordion-item.active-submenu > .accordion-collapse {
  background-color: #f0f8ff;
  /* Very light blue background for active submenu content */
}

.contentfulsidebar li.active-content > a {
  font-weight: bold;
  color: #004080;
  /* Darker blue for active content */
}

/* Ensure the active submenu stays open */
.contentfulsidebar .accordion-item.active-submenu > .accordion-collapse.show {
  /* display: block !important; */
}

/* Nested submenu styles */
.contentfulsidebar .nested-accordion {
  margin-left: 15px;
}

.contentfulsidebar .nested-accordion .accordion-item {
  /* border-left: 1px solid #ddd; */
}

.contentfulsidebar .nested-accordion .accordion-button {
  font-size: -0.9em;
  padding: 3px 6px;
}

.contentfulsidebar .nested-accordion .accordion-body {
  padding-left: 15px;
}

/* Highlight styles for nested submenus */
.contentfulsidebar
  .nested-accordion
  .accordion-item.active-submenu
  > .accordion-button {
  background-color: #e6f2ff;
  color: #0066cc;
}

.contentfulsidebar
  .nested-accordion
  .accordion-item.active-submenu
  > .accordion-collapse {
  background-color: #106bbb;
}

/* Ensure nested submenus stay open when active */
.contentfulsidebar
  .nested-accordion
  .accordion-item.active-submenu
  > .accordion-collapse.show {
  display: block !important;
}

/* Nested submenu styles for dropdown
.nested-submenu {
  padding-left: 10px;
}

.nested-submenu-item {
  font-size: -0.9em;
} */

/*=================================== My style =================================*/
.top-navbar .container.custom-container {
  display: block;
}

.contentfulsidebar {
  width: 100%;
  max-width: 280px;
  position: absolute;
  top: 168px;
  left: 20px;
  background-color: transparent;
  padding: 0 0 0;
}

.contentfulsidebar > h3 {
  padding: 15px 20px;
  background: linear-gradient(to bottom, #765591 0%, #a45292 100%);
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  border-radius: 10px 10px 0 0;
}
.contentfulsidebar > h3 a {
  text-decoration: none;
  color: #fff;
}

.contentfulsidebar > .accordion {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
}

.contentfulsidebar .accordion .accordion-item {
  border-radius: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

.contentfulsidebar .accordion .accordion-item .accordion-header button {
  background-color: #fff;
  border-radius: 0;
  margin: 0;
  padding: 18px 20px 17px;
  border: 0;
  box-shadow: none;
}

.contentfulsidebar
  .accordion
  > .accordion-item.active-submenu
  > .accordion-header
  > button,
.contentfulsidebar
  .accordion
  > .accordion-item:hover
  > .accordion-header
  > button {
  background-color: #087dad;
  background-color: #f8f8f8;
}

.contentfulsidebar .accordion .accordion-item .accordion-header button a {
  width: 100%;
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #282828;
  font-size: 16px;
}

.contentfulsidebar
  > .accordion
  > .accordion-item
  > .accordion-header
  > button
  > a {
  position: absolute;
  left: 0;
  padding-left: 20px;
}

.contentfulsidebar
  .accordion
  > .accordion-item.active-submenu
  > .accordion-header
  > button
  > a,
.contentfulsidebar
  .accordion
  > .accordion-item:hover
  > .accordion-header
  > button
  > a {
  color: #282828;
}

.contentfulsidebar .accordion-item > .accordion-collapse {
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 10px 20px;
}

.contentfulsidebar .accordion-item.active-submenu > .accordion-collapse {
  background-color: #e7e7e78c;
}

.contentfulsidebar .accordion-item > .accordion-collapse ul {
  margin: 0;
  padding: 0;
}

.contentfulsidebar .accordion-item > .accordion-collapse ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #cccccc61;
}

.contentfulsidebar .accordion-item > .accordion-collapse ul li a {
  text-decoration: none;
  margin: 0;
  padding: 8px 0px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  display: block;
}

.contentfulsidebar .accordion-item > .accordion-collapse ul li a:hover,
.contentfulsidebar
  .accordion-item
  > .accordion-collapse
  ul
  li.active-content
  a {
  color: #617f90;
}

.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item
  + .accordion-item {
  border-top: 1px solid #cccccc61;
}

.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item
  .accordion-header
  button {
  background-color: #e7e7e78c;
  border: 0;
  border-radius: 0;
  padding: 5px 0px 5px 0px;
  font-weight: 500;
}

.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item
  .accordion-header
  button
  a {
  text-decoration: none;
  margin: 0;
  padding: 8px 0px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  display: block;
}

.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item.active-submenu
  .accordion-header
  button
  a,
.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item:hover
  .accordion-header
  button
  a {
  color: #617f90;
}

.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item
  .accordion-collapse {
  padding: 0 0px 0 15px;
  background-color: #e7e7e78c;
}

.contentfulsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body {
  padding: 0;
}

.top-navbar.bg-light {
  border: 0;
}

.menu-navbar.bg-light {
  background-color: #41b6e6 !important;
}

.menu-navbar.bg-light #basic-navbar-nav > .navbar-nav {
  width: 100%;
}

.menu-navbar.bg-light #basic-navbar-nav > .navbar-nav > a {
  color: #282828;
  border-radius: 6px;
  padding: 11px 18px 11px;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
}
.menu-navbar.bg-light #basic-navbar-nav > .navbar-nav > a:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d96bac+0,883565+100 */
  background: linear-gradient(
    to bottom,
    #d96bac 0%,
    #883565 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #fff;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  > a {
  color: #282828;
  border-radius: 6px;
  padding: 11px 18px 11px;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
}
.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  > a
  > a {
  color: #282828;
  text-decoration: none;
}
.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown:hover
  > a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d96bac+0,883565+100 */
  background: linear-gradient(
    to bottom,
    #d96bac 0%,
    #883565 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #fff;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown:hover
  > a
  > a {
  color: #fff;
}

.menu-navbar.bg-light #basic-navbar-nav > .navbar-nav > .multi-column-dropdown {
  position: static;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown:nth-child(3) {
  position: relative;
}
.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown:nth-child(3)
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column {
  width: 100%;
  padding: 0;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu {
  padding: 11px 0 0;
  margin: 0;
  width: 100%;
  background-color: transparent;
}
.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu:before {
  content: "";
  border-top: 8px solid #ffffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  top: 0px;
  left: 139px;
  transform: rotate(178deg);
}
.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown:nth-child(3)
  .dropdown-menu:before {
  left: 60px;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content {
  padding: 15px 15px;
  width: 100%;
  max-width: 1000px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 5px 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 -1%;
  overflow: hidden;
  display: block;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column {
  float: left;
  width: 25%;
  padding: 0 15px 0 0;
  display: block;
  flex: none;
  margin: 0 0 15px;
  min-width: initial;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column
  > a {
  margin: 0 0 4px;
  padding: 0 0 0 2px;
  font-size: 14px;
  text-decoration: none;
  color: #282828;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column
  > a:hover {
  background-color: transparent;
  color: #617f90;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column
  > a.dropdown-header {
  font-size: 15px;
  color: #617f90;
  border: 0;
  margin: 0 0 4px;
  padding: 0;
  background-color: transparent;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column
  .nested-submenu {
  padding: 4px 0 0 0px;
  margin: 0;
  background-color: transparent;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column
  .nested-submenu
  a.nested-submenu-item {
  padding: 0;
  margin: 0 0 5px;
  background-color: transparent;
  font-size: 13px;
  color: #282828;
}

.menu-navbar.bg-light
  #basic-navbar-nav
  > .navbar-nav
  > .multi-column-dropdown
  .dropdown-menu
  .dropdown-menu-content
  .dropdown-column
  .nested-submenu
  a.nested-submenu-item:hover {
  color: #617f90;
}

.contentfulsidebar
  > .accordion
  > .accordion-item
  > .accordion-header
  > button.accordion-button:not(.collapsed)::after {
  /* filter: brightness(0) invert(1); */
}

.gridentWrapper {
  /* background: linear-gradient(to bottom,  #ffffff 0%,#c8609d 100%); */
  background-image: url(../../assets/nmg_banner_grad_grey.png);
  background-repeat: repeat-x;
}
.gridentWrapper nav.top-navbar,
.gridentWrapper nav.menu-navbar {
  background-color: transparent !important;
}
.gridentWrapper nav.menu-navbar {
  /* border-top: 3px solid #ad3476; */
  border-bottom: 3px solid #ad3477;
}
.top-navbar .Logo,
.top-navbar .Logo a {
  color: #25aba6;
  font-size: 25px;
  line-height: normal;
  font-weight: 300;
  text-decoration: none;
}
.top-navbar .Logo span {
  color: #5c5c5c;
  position: relative;
  padding-left: 10px;
}
.top-navbar .Logo span:before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 0;
  width: 1px;
  height: 65%;
  background-color: #5c5c5c;
  bottom: 0;
  margin: auto;
}
.top-navbar .tagLine {
  font-size: 27px;
  font-weight: 300;
  text-transform: uppercase;
  background-image: linear-gradient(#cccccce0, #282828);
  color: transparent;
  background-clip: text;
}
.header-right {
  display: block;
  width: 100%;
  max-width: 360px;
}
.header-right .contactInfo {
  margin: 0 0 5px;
}
.header-right .contactInfo a {
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  color: #5c5c5c;
}
.header-right .contactInfo a:hover {
  color: #cb5092;
}
.header-right .contactInfo a + a {
  margin-left: 15px;
  position: relative;
}
.header-right .contactInfo a + a:before {
  content: " ";
  position: absolute;
  top: 0;
  left: -8px;
  width: 1px;
  height: 65%;
  background-color: #5c5c5cd3;
  bottom: 0;
  margin: auto;
}
.header-right .searchForm {
  position: relative;
}
.header-right .searchForm input {
  width: 100%;
  border-radius: 4px;
  margin: 0;
  outline: none;
}
.header-right .searchForm button {
  background-color: #fff;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  color: #5c5c5c;
  position: absolute;
  top: 1px;
  right: 1px;
  font-size: 16px;
  height: 36px;
  width: 38px;
}
.header-right .searchForm button:hover {
  background-color: #fff;
}
#basic-navbar-nav .dropdown-item {
  white-space: normal !important;
}

@media only screen and (max-width: 991px) {
  .menu-navbar.bg-light
    #basic-navbar-nav
    > .navbar-nav
    > .multi-column-dropdown
    .dropdown-menu
    .dropdown-menu-content {
    grid-template-columns: repeat(1, 1fr);
  }

  .menu-navbar.bg-light
    #basic-navbar-nav
    > .navbar-nav
    > .multi-column-dropdown {
    position: relative;
  }

  .menu-navbar.bg-light
    #basic-navbar-nav
    > .navbar-nav
    > .multi-column-dropdown
    .dropdown-menu
    .dropdown-menu-content {
    position: static;
    flex-direction: column;
  }

  .menu-navbar.bg-light
    #basic-navbar-nav
    > .navbar-nav
    > .multi-column-dropdown
    .dropdown-menu {
    display: none;
    padding: 0;
  }

  .menu-navbar.bg-light
    #basic-navbar-nav
    > .navbar-nav
    > .multi-column-dropdown
    .dropdown-menu.show {
    display: block;
  }

  .menu-navbar.bg-light {
    margin-bottom: 20px;
  }
  .menu-navbar.bg-light
    #basic-navbar-nav
    > .navbar-nav
    > .multi-column-dropdown
    .dropdown-menu:before {
    display: none !important;
  }
}

@media only screen and (max-width: 640px) {
  .contentfulsidebar {
    position: static;
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 0;
  }

  .content-page {
    min-height: initial;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
  }
}
/* style for no content pages and no submenus */
.simple-link {
  color: #333;
  font-weight: bold;
  text-decoration: none;
  padding: 18px 20px 17px;
  display: flex;
}

.simple-link:hover {
  background-color: #f8f8f8;
}

.no-arrow .dropdown-toggle::after {
  display: none;
}
