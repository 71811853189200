@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* Reset default styles */
body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Inter", sans-serif !important;
}
/* Main Content Layout */
.main-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8;
}
/* Forms Layout */
.forms-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
}
.forms-column {
  width: 60%;
  height: 10%;
  border: 1px solid #e0e0e0;
  padding: 15px;
  background-color: #fff;
}
.forms-column h3 {
  font-size: 16px;
  margin-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 5px;
}
/* Compliance Tabs */
.compliance-tabs {
  margin-top: 20px;
}
/* Ensure dropdown content shows on hover */
.nav-item:hover>.dropdown-menu-content {
  display: flex;
  flex-wrap: nowrap;
  /* Show dropdown content on hover */
}
/* Dropdown Container Styling */
.nav-item {
  position: relative;
  /* Position relative for absolute child positioning */
  margin: 0;
  /* Remove any margin that could create a gap */
  padding: 0;
  /* Remove padding */
}
.content-layout {
  display: flex;
}
.main-content {
  flex: 1;
  padding: 20px;
}
/* Reset default styles */
body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}
/* Main Content Layout */
.main-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8;
}
/* Forms Layout */
.forms-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
}
.forms-column {
  width: 20%;
  border: 1px solid #e0e0e0;
  padding: 15px;
  background-color: #fff;
}
.forms-column h3 {
  font-size: 16px;
  margin-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 5px;
  font-weight: bold; 
}
/* Compliance Tabs */
.compliance-tabs {
  margin-top: 20px;
}
.nav-item:hover>.dropdown-menu-content {
  display: flex;
  /* Show dropdown content on hover */
}
/* Error message */
.error-message {
  color: red;
  display: block;
  margin-top: 0.5rem;
}
/* Textarea */
textarea {
  overflow: none;
  line-height: 25px;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}
/* Adjust form field spacing */
.form-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* Reduce margin between fields */
}
/* Adjust label text size */
.form-field label {
  width: 80px;
  /* Keep the reduced width */
  font-weight: bold;
  margin-right: 5px;
  /* Maintain reduced space between label and input */
  font-size: 12px;
  /* Decrease font size for labels */
}
/* Adjust input field styling */
.form-field input {
  flex: 1;
  padding: 5px;
  /* Reduce padding inside input */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  /* Ensure input field takes full available space */
}
/* Adjust OR divider spacing */
.or-divider {
  text-align: center;
  margin: 10px 0;
  /* Reduce top and bottom margin */
  font-weight: bold;
  color: #666;
  font-size: 14px;
}
/* Adjust button container alignment */
.button-container {
  display: flex;
  justify-content: center;
}
/* Button Styling */
button[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ad3477;
  color: white;
  border: none;
  border-radius: 4px;
  max-height: 37px;
  padding: 8px 15px;
  /* Reduce padding for a more compact button */
  font-size: 12px;
  /* Adjust font size */
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button[type="submit"]:hover {
  background-color: #8a255c;
}
button[type="submit"]:active {
  background-color: #8a255c;
}
button[type="submit"] i {
  margin-right: 5px;
  /* Adjust icon spacing */
}
.preLoder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.HomeFormWrapper {
  width: 100%;
  padding: 0px 20px 20px;
}
.HomeFormWrapper .forms-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  min-height: 344.53px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.HomeFormWrapper .forms-column button {
  background-color: #ad3477;
  text-transform: uppercase;
  font-size: 14px;
}
.HomeFormWrapper .forms-column button:hover {
  background-color: #8a255c;
}
.mx-10 {
  margin-left: 10%;
  margin-right: 10%;
}
.ml-2 {
  margin-left: 2% !important;
}

.header-description {
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #b61313;
  line-height: 1.6;
}

/* Secondary CTA - Clear Button */
button.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* No background to make it secondary */
  color: #ad3477; /* Matches primary color */
  border: 2px solid #ad3477; /* Adds a border to distinguish */
  border-radius: 4px;
  height: 37px; /* Match Submit button height */
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
}

button.clear-button:hover {
  background-color: #f8f8f8; /* Light background on hover */
}

button.clear-button:active {
  background-color: #e0e0e0;
}

/* Ensure table takes full width */
.table-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling if needed */
}

/* Increase the table width */
table {
  width: 120%; /* Increase width beyond 100% to create extra space */
  table-layout: fixed; /* Ensures columns keep their assigned width */
  border-collapse: collapse;
}

/* Ensure headers and data cells stay in one line */
th, td {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds "..." if text overflows */
  padding: 10px;
}

/* Ensure only the PO Number column stays in one line without hiding text */
th:nth-child(1), td:nth-child(1) {
  white-space: wrap;                /* Prevents wrapping */
  min-width: 250px;                 /* Increases space for full visibility */
  max-width: 300px;                 /* Allows some flexibility */
  text-align: left;                  /* Align text properly */
  overflow: visible;                  /* Ensures text is fully shown */
  padding: 10px;                     /* Maintain spacing */
}

/* Override button to allow text wrapping */
button.routing-guide-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ad3477;
  color: white;
  border: none;
  border-radius: 4px;
  max-height: 100%;
  padding: 8px 15px;
  /* Reduce padding for a more compact button */
  font-size: 12px;
  /* Adjust font size */
  text-transform: uppercase;
  cursor: pointer;
  white-space: normal !important;  /* Allows text wrapping */
  word-wrap: break-word !important; /* Ensures long words wrap */
}
