.stores-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto; /* Increased margin for better spacing */
    padding: 20px;
    max-width: 90%;
  }
  
  .stores-header {
    text-align: left;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .stores-table {
    width: 100%;
    max-width: 1200px; /* Prevents table from being too wide */
    border-collapse: collapse;
    margin: 20px auto; /* Centers the table and provides space around it */
  }
  
  .stores-table th,
  .stores-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .stores-table th {
    background-color: #993366;
    font-weight: bold;
  }
  
  .stores-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .stores-table tr:hover {
    background-color: #f1f1f1;
  }
  