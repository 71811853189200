/* Footer Section */
.footer {
  background-color: #000;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
  margin-top: 0;
}
.footer {
  text-align: center;
  padding: 20px;
}
.footer_logos{
  border-top: 1px solid #e6e6e6;
  padding: 10px 15px;
}
.footer-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8%; /* Space between images */
  margin: 0 0 0; /* Remove extra margin */
  padding: 0; /* Remove extra padding if any */
}

.footer-images img {
  margin: 5px;
  width: 100px;
  aspect-ratio: 91 / 38;
  height: 45px;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  object-fit: contain;
  object-position: center;
}

.footer-text {
  color: #888;
  background-color: black;
  padding: 0; /* Remove extra padding */
  margin: 0; /* Remove extra margin */
  font-size: 12px;
}

.footer-text p {
  height: initial;
  margin: 0; /* Remove default margin from paragraphs */
  padding: 4px 0; /* Add vertical padding if needed */
}

/* Footer styles */
.footer-links {
text-align: center;
/* margin-top: 20px; */
font-size: 14px;
color: #333; /* Adjust text color as needed */
}

.footer-links a {
color: #f0f0f0; /* Adjust link color as needed */
text-decoration: none;
/* margin: 0 px; */
transition: color 0.3s ease;
}

.footer-links a:hover {
color: #ffffff; /* Adjust hover color as needed */
}
