.banner-container {
  position: relative; /* To position the alerts box correctly */
  margin: 20px; /* Adds margin around the entire banner section */
  border-radius: 8px; /* Rounded corners for a softer look */
  overflow: hidden; /* Ensures content stays within rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  min-height: 200px; /* Prevent layout shifts by reserving space */
}

.carousel-item img {
  border-radius: 8px; /* Rounded corners for images */
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
}

/* The alerts box will still be absolutely positioned to overlap the carousel */
.alerts-box {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a shadow to make it stand out */
  max-width: 300px; 
  max-height: 400px; /* Ensures the alerts box doesn't get too wide */
  z-index: 10; /* Ensures it stays above the carousel images */
  overflow: auto; /* Allow scrolling if content exceeds max-height */
}

/* Heading styles for the accordion */
.alerts-box h4 {
  margin: 0 0 10px; /* Margin below the header */
  font-size: 16px;
  color: #333;
}

/* Accordion container styles */
.accordion-container {
  max-width: 100%; /* Make sure it adapts to the alerts box */
}

.accordion-container .accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  font-size: 16px;
  color: #333;
}

.accordion-body {
  font-size: 14px;
  color: #060000;
  padding-left: 10px;
  list-style-type: disc;
}

.accordion-body ul {
  padding-left: 20px;
  margin: 0;
}

.accordion-body li {
  margin-bottom: 5px;
}

/* Ensuring the accordion container stays inside the alerts box and doesn't overflow */
.alerts-box {
  width: 100%; /* Full width of the alerts box */
}

.alerts-box .accordion-container {
  max-width: 100%; /* Adapt to the alerts box */
}

.alerts-box button {
  background-color: transparent;
  border: 0;
  outline: none;
  color: #617f90;
  font-weight: 700;
}


.alerts-box button:hover {
  color: #8f517b;
}

.alerts-box ul {
  margin: 0;
  padding: 0;
  
}

.alerts-box ul li {
  list-style: none;
  color:#060000;

}

.alerts-box ul li a {
  text-decoration: none;
  color: #617f90;
}

.alerts-box ul li a:hover {
  color: #8f517b;
}

.alerts-box .accordion .accordion-item .accordion-header button {
  background: linear-gradient(to bottom, #765591 0%, #a45292 100%);
  color: #fff;
}

.alerts-box .accordion .accordion-item .accordion-header button.collapsed {
  background: #e0e0e0;
  color: #444;
}

.alerts-box .accordion .accordion-item .accordion-header button.collapsed::after {
  filter: unset; /* Ensure the icon displays correctly */
}

.banner-container .accordion-button::after {
  filter: brightness(0) invert(1); /* Icon styling */
}

/* Media Queries for Responsiveness */
@media (max-width: 600px) {
  .banner-container {
    margin: 10px; /* Smaller margin on mobile */
  }

  .alerts-box {
    max-width: 90%; /* Adjust max-width for smaller screens */
  }
}
