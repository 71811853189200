.alert_wrapper{
  width: 100%;
  display: block;
  margin: 0;
  padding: 20px;
}
.alert_wrapper .sidebar_and_content{
  display: flex;
  flex-wrap: wrap;
}
.alert_wrapper .sidebar{
  padding: 0;
  margin: 0;
  background-color: #e7e7e78c;
  max-width: 280px;
  border-radius: 10px;    
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  flex: 1 0 0%;
}
.alert_wrapper .sidebar h3{
  padding: 15PX 20PX;
  background: linear-gradient(to bottom, #765591 0%, #a45292 100%);
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  border-radius: 10px 10px 0 0;
}
.alert_wrapper .sidebar h3 > a{
  color: #fff;
  text-decoration: none;
}
.alert_wrapper .sidebar ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.alert_wrapper .sidebar ul li{
  margin: 0;
  text-decoration: none;
  padding: 8px 20px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
.alert_wrapper .sidebar ul li + li{
  border-top: 1px solid #cccccc61;
}
.alert_wrapper .sidebar ul li.active-tab,
.alert_wrapper .sidebar ul li:hover{
  color: #617f90;
}

.alerts-content{
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 20px;
}
.border_area{
  border: 1px solid #e3e3e3;
  padding: 15px;
  border-radius: 10px;
}
.alerts-content h2{
  font-size: 2em;
  margin-bottom: 15px;
  color: #8f517b;
}
.alerts-content h3,
.alerts-content h4,
.alerts-content h5,
.alerts-content h6{
color: #444;
font-weight: 600;
margin-bottom: 10px;
}
.alerts-content p{
font-size: 16px;
color: #444;
line-height: 28px;
margin: 0 0 10px;
}
.alerts-content ul li,
.alerts-content ul li{
list-style-type: none;
font-size: 16px;
color: #444;
line-height: 24px;
background: url(../../assets/icon1.png) no-repeat left 9px;
padding-left: 16px;
margin: 0 0 8px;
}
.alerts-content ul li p{
margin: 0;
}
.alerts-content ol li{
font-size: 16px;
color: #444;
line-height: 24px;
margin: 0 0 8px;
}
.alerts-content ol li p{
margin: 0;
}
.alerts-content ul.nav-tabs{
list-style-type: none;
margin: 0;
padding: 0;
}
.alerts-content ul.nav-tabs li{
list-style-type: none;
margin: 0;
padding: 0;
}
.alerts-content .tab-content{
margin: 0;
border: 0;
border-radius: 0;
border-left: 1px solid #dee2e6;
border-right: 1px solid #dee2e6;
border-bottom: 1px solid #dee2e6;
}
.alerts-content p a{
color: #617f90;
}
.alerts-content p a:hover{
color: #c2185b;
}
.alerts-content img{
margin: 0 0 15px;
max-width: 100%;
height: auto !important;
display: block;
border: 2px solid #fff;
box-shadow: rgba(0,0,0,0.3)0 0 10px;
border-radius: 6px;
}
.filter_box {
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  margin-bottom: 20px;
}
.tab-content {
  font-size: 14px; /* Reduced base font size for all content within the tab */
  overflow: hidden; /* Prevents content from overflowing outside the tab */
  padding: 0.5em; /* Reduced padding inside the tab content area */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  line-height: 1.3; /* Reduced line height for less vertical spacing */
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders are joined */
  margin-bottom: 30px; /* Reduced space below the table */
  border: 1px solid #333; /* Dark border around the entire table */
}

th, td {
  border: 1px solid #333; /* Dark border for all four sides of each cell */
  padding: 0 0.2em; /* Adds space on both left and right sides */
  text-align: left; /* Aligns text to the left */
  white-space: normal; /* Allows text wrapping within cells */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Shows ellipsis (...) for overflowed text */
  word-wrap: break-word; /* Breaks long words to fit within the cell */
  line-height: 1 !important; /* Minimal line height for tighter line spacing */
}

th {
  background-color: #f4f4f4; /* Light gray background for header cells */
  font-weight: bold; /* Bold font for header cells */
  border-bottom: 2px solid #333; /* Slightly thicker dark border for header cells */
  padding: 0 0.2em; /* Adds space on both left and right sides for header cells */
}

tr:nth-child(even) {
  background-color: #f9f9f9; /* Zebra striping for even rows */
}

tr:hover {
  background-color: #eaeaea; /* Highlights row on hover */
}

p:empty{
  display: none;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active{
color: #2c2c2c;
}
.alerts-content table tr:first-child,
.alerts-content table tr th {
  border-top: none;
  background: #087dad;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d96bac+0,8f3a6b+100 */
  background: linear-gradient(to bottom,  #d96bac 0%,#8f3a6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #fff;
}
.alerts-content table tr:first-child p,
.alerts-content table tr th p,
.alerts-content table tr th p a,
.alerts-content table tr:first-child p a{
  margin: 0;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
}
.alerts-content table tr th p a,
.alerts-content table tr:first-child p a{
  text-decoration: underline;
}
.alerts-content table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}
.alerts-content table tr p,
.alerts-content table tr th p{
  margin: 0;
  font-size: 15px;
}
.alerts-content table tr:nth-child(odd):not(:first-child) {
  background-color: #c9619e0f;
}

.alerts-content table th {
  display: none;
}

.alerts-content table td {
  display: block;
}

.alerts-content table td:first-child {
  margin-top: .5em;
}

.alerts-content table td:last-child {
  margin-bottom: .5em;
}
.alerts-content table th,
.alerts-content table td {
  text-align: left;
}

.alerts-content table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.alerts-content table tr {
  border-color: #bfbfbf;
}

.alerts-content table th,
.alerts-content table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .alerts-content table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .alerts-content table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .alerts-content table td:before {
    display: none;
  }
  .alerts-content table th,
  .alerts-content table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .alerts-content table th:first-child,
  .alerts-content table td:first-child {
    padding-left: 0;
  }
  .alerts-content table th:last-child,
  .alerts-content table td:last-child {
    padding-right: 0;
  }
  .alerts-content table th,
  .alerts-content table td {
    padding: 1em !important;
  }
}

#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr{
  background-color: transparent;
}
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p{
  font-size: 16px;
  color: #282828;
  line-height: 28px;
  margin: 0 0 10px;
  text-transform: none;
  font-weight: 400;
}
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p a{
  color: #138abb;
}
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p a:hover{
  color: #c2185b;
}
.content-area-accordion .accordion-header{
  margin: 0;
}  
.content-area-accordion .accordion-header button{
  padding: 8px 10px;
}