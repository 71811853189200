/* TabsComponent.css */

/* Apply a smaller base font size for the entire tab content */
.tab-content {
  font-size: 14px; /* Reduced base font size for all content within the tab */
  overflow: hidden; /* Prevents content from overflowing outside the tab */
  padding: 0.5em; /* Reduced padding inside the tab content area */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  line-height: 1.3; /* Reduced line height for less vertical spacing */
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders are joined */
  margin-bottom: 30px; /* Reduced space below the table */
  border: 1px solid #333; /* Dark border around the entire table */
}

th,
td {
  border: 1px solid #333; /* Dark border for all four sides of each cell */
  padding: 0 0.2em; /* Adds space on both left and right sides */
  text-align: left; /* Aligns text to the left */
  white-space: normal; /* Allows text wrapping within cells */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Shows ellipsis (...) for overflowed text */
  word-wrap: break-word; /* Breaks long words to fit within the cell */
  line-height: 1 !important; /* Minimal line height for tighter line spacing */
}

th {
  background-color: #f4f4f4; /* Light gray background for header cells */
  font-weight: bold; /* Bold font for header cells */
  border-bottom: 2px solid #333; /* Slightly thicker dark border for header cells */
  padding: 0 0.2em; /* Adds space on both left and right sides for header cells */
}

tr:nth-child(even) {
  background-color: #f9f9f9; /* Zebra striping for even rows */
}

tr:hover {
  background-color: #eaeaea; /* Highlights row on hover */
}

/* Ensures consistent dark borders on all sides */

/*================================= My style ===================================*/
p:empty {
  display: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #2c2c2c;
}
.content-page table tr:first-child,
.content-page table tr th {
  border-top: none;
  background: #087dad;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d96bac+0,8f3a6b+100 */
  background: linear-gradient(
    to bottom,
    #d96bac 0%,
    #8f3a6b 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #fff;
}
.content-page table tr:first-child p,
.content-page table tr th p,
.content-page table tr th p a,
.content-page table tr:first-child p a {
  margin: 0;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.content-page table tr th p a,
.content-page table tr:first-child p a {
  text-decoration: underline;
}
.content-page table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}
.content-page table tr p,
.content-page table tr th p {
  margin: 0;
  font-size: 15px;
}
.content-page table tr:nth-child(odd):not(:first-child) {
  background-color: #c9619e0f;
}

.content-page table th {
  display: none;
}

.content-page table td {
  display: block;
}

.content-page table td:first-child {
  margin-top: 0.5em;
}

.content-page table td:last-child {
  margin-bottom: 0.5em;
}

/* .content-page table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: #000;
  } */

.content-page table th,
.content-page table td {
  text-align: left;
}

.content-page table {
  color: #333;
  border-radius: 0.4em;
  overflow: hidden;
}

.content-page table tr {
  border-color: #bfbfbf;
}

.content-page table th,
.content-page table td {
  padding: 0.5em 1em;
}
@media screen and (max-width: 601px) {
  .content-page table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .content-page table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .content-page table td:before {
    display: none;
  }
  .content-page table th,
  .content-page table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .content-page table th:first-child,
  .content-page table td:first-child {
    padding-left: 0;
  }
  .content-page table th:last-child,
  .content-page table td:last-child {
    padding-right: 0;
  }
  .content-page table th,
  .content-page table td {
    padding: 1em !important;
  }
}

#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr {
  background-color: transparent;
}
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p {
  font-size: 16px;
  color: #282828;
  line-height: 28px;
  margin: 0 0 10px;
  text-transform: none;
  font-weight: 400;
}
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p a {
  color: #138abb;
}
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p a:hover {
  color: #c2185b;
}
.content-area-accordion .accordion-header {
  margin: 0;
}
.content-area-accordion .accordion-header button {
  padding: 8px 10px;
}

/* Basic table structure */
.content-page table {
  color: #333;
  border-radius: 0.4em;
  overflow: hidden;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed; /* Forces equal column widths */
  position: relative;
}

/* Sticky header styles */
.content-page table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Header styles */
.content-page table tr:first-child,
.content-page table tr th {
  position: sticky;
  top: 0;
  background: linear-gradient(to bottom, #d96bac 0%, #8f3a6b 100%);
  color: #fff;
  border: none;
}

/* Header text styles */
.content-page table tr:first-child p,
.content-page table tr th p,
.content-page table tr th p a,
.content-page table tr:first-child p a {
  margin: 0;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Cell spacing and alignment */
.content-page table th,
.content-page table td {
  padding: 1em;
  text-align: left;
  vertical-align: top;
  border-right: 1px solid #ddd; /* Add divider between columns */
}

/* Remove right border from last column */
.content-page table th:last-child,
.content-page table td:last-child {
  border-right: none;
}

/* Row styles */
.content-page table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

/* Alternating row colors */
.content-page table tr:nth-child(odd):not(:first-child) {
  background-color: #c9619e0f;
}

/* Hover effect */
@media screen and (min-width: 600px) {
  .content-page table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
}

/* Responsive table styles */
@media screen and (max-width: 599px) {
  .content-page table th {
    display: none;
  }

  .content-page table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-right: none; /* Remove right border for responsive cells */
  }

  .content-page table td:first-child {
    margin-top: 0.5em;
  }

  .content-page table td:last-child {
    margin-bottom: 0.5em;
  }
}

/* Custom styles for specific table */
#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr {
  background-color: transparent;
}

#tab-content-7IazODdlQP6dlDnBUlR8j4 table tbody tr p {
  font-size: 16px;
  color: #282828;
  line-height: 28px;
  margin: 0 0 10px;
  text-transform: none;
  font-weight: 400;
}